<template>
  <div v-loading="pageLoading">
    <div class="card mb-5 mb-xl-10" v-if="oldPoductData">
      <div class="card-header border-0 pt-6">
        <div class="d-flex flex-column">
          <div class="d-flex align-items-center mb-2 float-left">
            <span class="text-gray-900 fs-2 fw-bolder me-1">{{ oldPoductData.name }}</span>
          </div>
          <div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 float-left">
            <span v-if="oldPoductData.description" class="d-flex align-items-center text-gray-400 me-5 mb-2" v-html="oldPoductData.description.length > 500 ? oldPoductData.description.substring(0,500)+'...' : oldPoductData.description"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="card bg-transparent">
      <div class="card-header border-0 p-0" v-loading="pageLoading">
        <div class="w-100" v-if="tableData.value">
          <div v-if="tableData.value.length > 0">
            <el-form :model="formData" ref="formRef" class="w-100">
              <div class="row">
                <div class="col-md-4 mb-10" v-for="masterOption in tableData.value" :key="masterOption.master_option_id">
                  <div class="h-100 bg-white p-5 shadow-sm">
                    <div class="row card-title border-bottom mb-3 fw-bolder text-primary p-3 m-0 justify-content-center">
                      <div class="row p-0">
                        <div class="col-md-12 px-0">
                          <h3 class="fs-3 text-center mb-3">{{ masterOption.option_name }}
                          </h3>
                        </div>                      
                      </div>
                      <div class="row align-items-baseline p-0">                      
                        <div class="col-md-4">
                          <label>SKU Sequence</label>
                          <el-tooltip class="box-item " effect="dark" content="Here you set SKU sequence" placement="right" raw-content>
                            <i class="bi bi-question-circle-fill mx-2"></i>
                          </el-tooltip>
                          <el-input size="small" v-model="formData[masterOption.master_option_id]['sku_sort_order']" type="text" /> 
                        </div>
                        <div class="col-md-5">
                          <div class="d-flex align-items-start flex-column">             
                            <div>
                              <label>SKU Combination</label>
                              <el-tooltip class="box-item " effect="dark" content="If you allow this then combination SKU will generate" placement="right" raw-content>
                                <i class="bi bi-question-circle-fill mx-2"></i>
                              </el-tooltip>
                            </div>
                            <el-switch v-model="formData[masterOption.master_option_id]['is_individual_sku_allow']" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="d-flex align-items-start flex-column">
                            <div>
                              <label v-if="masterOption.isAssigned">Status</label>
                              <el-tooltip v-if="masterOption.isAssigned" class="box-item " effect="dark" content="Here you can set active or deactive status for particular product option" placement="right" raw-content>
                                <i class="bi bi-question-circle-fill mx-2"></i>
                              </el-tooltip>
                            </div>
                            <el-switch v-if="masterOption.isAssigned" v-model="masterOption.isStatus" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" @change="changeStatus($event, productID, masterOption.master_option_id)" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row align-items-center mb-2">
                      <div class="col-md-4">
                        <label class="fw-bold">{{ constants.product.ATTRIBUTE }}</label>
                      </div>
                      <div class="col-md-5">
                        <label class="fw-bold">
                          {{ constants.productSku.MODIFYSKU }}
                          <el-tooltip class="box-item" effect="dark" :content="constants.product.MODIFY_SKU_HELP" placement="right" raw-content>
                            <i class="bi bi-question-circle-fill me-2"></i>
                          </el-tooltip>
                          <i class="bi bi-pencil-square" @click="showSkuCode"></i>
                        </label>
                      </div>
                      <div class="col-md-3">
                        <label class="fw-bold d-block">{{ constants.general.SORTORDER }} </label>
                      </div>
                    </div>
                    <div class="row align-items-center" v-for="masterAttribute in masterOption.option_attributes" :key="masterAttribute.master_attribute_id">
                      <div class="col-md-4 mb-1">
                        <div class="form-check">
                          <input v-model="formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['is_checked']" class="form-check-input" type="checkbox" :id="formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['master_attribute_id']" checked>
                          <label class="form-check-label" :for="formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['master_attribute_id']">
                            {{ masterAttribute.attribute_name }} <span class="text-danger" v-if="masterAttribute.status == '0' "> (X) </span>
                          </label>
                        </div>

                      </div>
                      <div class="col-md-5 mb-1" v-if="showSkuLabel">
                        <label :class="'text-black-50 ' + (getKeyCountByValue(formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code']) > 1 ? 'bg-warning text-white px-2 py-1' : '') ">{{ formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code'] }}</label>
                      </div>
                      <div class="col-md-4 mb-1" v-if="showAssignedSKu">
                        <el-input v-model="formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['assinged_sku_code']" class="text-capitalize" size="medium" />
                      </div>
                      <div v-if="showAssignedSKu" class="col-md-1" />
                      <div class="col-md-3">
                        <span>{{formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['sort_order']}}</span>
                        <!-- <el-input-number v-model="formData[masterOption.master_option_id][masterAttribute.master_attribute_id]['sort_order']" class="my-2 w-100" size="default" controls-position="right" /> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-5">
                <button type="button" class="btn btn-primary me-2" :disabled="loading" @click="submitForm(formRef, 'save')">{{ constants.general.SAVE }}<span v-if="loading" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class="btn btn-primary me-2" :disabled="loadingback" @click="submitForm(formRef, 'save_back')">{{ constants.general.SAVEANDBACK }}<span v-if="loadingback" class="spinner-border spinner-border-sm align-middle ms-2"></span></button>
                <button type="button" class="btn btn-secondary me-2" :disabled="disabled" @click.prevent="$router.push('/products')">{{ constants.general.CANCEL }}</button>
              </div>
            </el-form>
          </div>
          <div v-else>
            <div class="card card-flush py-4 flex-row-fluid">
              <div class="card-body">
                <div class="d-flex flex-column flex-center flex-column-fluid p-10">
                  <h1 class="fw-bold">
                    {{ constants.product.NO_OPTION_FOUND }}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.w-20 {
  width: 20% !important;
}
.text-capitalize > input {
  text-transform: uppercase;
}
.input-label {
  font-size: 12px;
  top: 27px;
}
.card-title label{
  font-size: 13px;
  font-weight: 500;
  color:#000;
  text-transform: capitalize;
}
.card-title{
  background-color: #eee;
}
</style>
<script>
import { reactive, ref, onMounted, onUpdated } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import { useRoute } from "vue-router";
import { useRouter } from "vue-router";
import { notificationFire } from "@/composable/notification.js";
import { formatText } from "@/core/helpers/common";
import globalConstant from "@/core/data/globalConstant.js";
import useEventBus from "../../composable/useEventBus";
import { setCurrentPageActionButtons } from "@/core/helpers/actionButtons";

export default {
  name: "additional-option-detail",
  setup() {
    const { onEvent, emitEvent } = useEventBus();
    const formRef = ref(null);
    const route = useRoute();
    const router = useRouter();
    const productID = ref();
    const tableData = reactive({});
    const formData = ref({});
    const disabled = ref(false);
    const loading = ref(false);
    const loadingback = ref(false);
    const showSkuLabel = ref(true);
    const showAssignedSKu = ref(false);
    const pageLoading = ref(false);
    const constants = globalConstant;
    const checkDuplicateSKU = ref([]);
    const oldPoductData = reactive({
      name: '',
      description: ''
    });

    const showSkuCode = () => {
      showAssignedSKu.value = true;
      showSkuLabel.value = false;
    };

    //Get click event of toolbar buttons
    onEvent("actionName", (actionName) => {
      if (actionName == "save") {
        submitForm(formRef, "save", true);
      } else if (actionName == "save_back") {
        submitForm(formRef, "save_back", true);
      } else if (actionName == "cancel") {
        router.push("/products");
      } else if (actionName == "back") {
        router.push("/products");
      }
    });

    const getKeyCountByValue = (val) => {
      return checkDuplicateSKU.value.filter((x) => x === val).length;
    };

    // Get Product Assigns Options
    const getMasterOptionDetails = async () => {
      pageLoading.value = true;
      await ApiService.query("assign-product-options", {
        params: {
          product_id: productID.value,
        },
      })
        .then((data) => {
          tableData.value = data.data.data.additionalData;
          checkDuplicateSKU.value = [];
          tableData.value.forEach((masterOption) => {
            formData.value[masterOption.master_option_id] = {};
            let optionStatus =
              masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.status
                : "0";
            formData.value[masterOption.master_option_id]["status"] =
              optionStatus;
            let optionSkuSortOrder = masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.sku_sort_order
                : masterOption.sort_order;
            formData.value[masterOption.master_option_id]["sku_sort_order"] =
              optionSkuSortOrder;
            let isIndidividualSkuAllow = masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.allow_individual_sku
                : "";
            formData.value[masterOption.master_option_id]["is_individual_sku_allow"] = masterOption.additional_assign_data != null ?
              String(masterOption.additional_assign_data.allow_individual_sku) : '';
            let assignedOptionId =
              masterOption.additional_assign_data != null
                ? masterOption.additional_assign_data.additional_option_id
                : "";
            let ab = [];
            masterOption.option_attributes.forEach((attr) => {
              let isAttributeAssigned =
                attr.assignedAttribute.length > 0 ? true : false;
              ab.push(isAttributeAssigned);
              formData.value[masterOption.master_option_id][
                attr.master_attribute_id
              ] = {};
              formData.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["master_attribute_id"] = attr.master_attribute_id;
              formData.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["master_option_id"] = attr.master_option_id;
              formData.value[masterOption.master_option_id][
                attr.master_attribute_id
              ]["sku_code"] = attr.sku_code;
              if (attr.assignedAttribute.length > 0) {
                if (
                  attr.assignedAttribute[0].sku_code != "" &&
                  attr.assignedAttribute[0].sku_code != null
                ) {
                  formData.value[masterOption.master_option_id][
                    attr.master_attribute_id
                  ]["assinged_sku_code"] = attr.assignedAttribute[0].sku_code;
                  checkDuplicateSKU.value.push(
                    attr.assignedAttribute[0].sku_code
                  );
                } else {
                  formData.value[masterOption.master_option_id][
                    attr.master_attribute_id
                  ]["assinged_sku_code"] = attr.sku_code;
                  checkDuplicateSKU.value.push(attr.sku_code);
                }
                formData.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["sort_order"] = attr.sort_order;
                formData.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["is_checked"] = true;
              } else {
                checkDuplicateSKU.value.push(attr.sku_code);
                formData.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["assinged_sku_code"] = attr.sku_code;
                formData.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["is_checked"] = false;
                formData.value[masterOption.master_option_id][
                  attr.master_attribute_id
                ]["sort_order"] = attr.sort_order;
              }
            });
            formData.value[masterOption.master_option_id]["status_visible"] =
              ab.some(Boolean) ? 1 : 0;
            masterOption.isAssigned = ab.some(Boolean);
            masterOption.isStatus = String(optionStatus);
            masterOption.isIndidividualSkuAllow = String(isIndidividualSkuAllow);
            masterOption.skuSortOrder = optionSkuSortOrder;
            masterOption.additional_option_id = assignedOptionId;
          });
          pageLoading.value = false;
          showAssignedSKu.value = false;
          showSkuLabel.value = true;
          oldPoductData.name = data.data.data.productData.name;
          oldPoductData.description = data.data.data.productData.description;
      })
      .catch((error) => {
        pageLoading.value = false;
        let message='';
        if (typeof (error.response) !== 'undefined') {
          message = error.response.data.message;
        } else if (typeof (error.message) !== 'undefined') {
          message = error.message;
        } else {
          message = "Something went wrong";
        }
        notificationFire(message, "error");
      });
    }

    //Change Additional Option Status
    const changeStatus = (status, productId, masterOptionId) => {
      ApiService.post("assign-product-options/status-update", {
        status: status,
        productId: productId,
        masterOptionId: masterOptionId,
      })
        .then(({ data }) => {
          notificationFire(data.message, "success");
          formData.value[masterOptionId]["status"] = status;
        })
        .catch((error) => {
          let message = "";
          if (typeof error.response !== "undefined") {
            message = error.response.data.message;
          } else if (typeof error.message !== "undefined") {
            message = error.message;
          } else {
            message = "Something went wrong";
          }
          notificationFire(message, "error");
        });
    };

    // Submit Data
    const submitForm = async (
      formEl,
      btnName,
      isToolbarButtonsSubmitAction = false
    ) => {
      formEl = isToolbarButtonsSubmitAction ? formEl.value : formEl;
      if (!formEl) return;
      isToolbarButtonsSubmitAction
        ? emitEvent("loader", { save_loader: true, action: btnName })
        : btnName == "save"
        ? (loading.value = true)
        : (loadingback.value = true);
      await formEl.validate((valid) => {
        let addParams = {
          action: btnName,
          product_id: productID.value,
          formData: JSON.stringify(formData.value),
        };
        ApiService.post("assign-product-options", addParams)
          .then((data) => {
            if (data) {
              notificationFire(data.data.message, "success");
              if (btnName == "save") {
                getMasterOptionDetails();
                isToolbarButtonsSubmitAction
                  ? emitEvent("loader", { save_loader: false, action: btnName })
                  : btnName == "save"
                  ? (loading.value = false)
                  : (loadingback.value = false);
              } else if (btnName == "save_back") {
                router.push({ name: "products" });
              }
            }
          })
          .catch((error) => {
            isToolbarButtonsSubmitAction
              ? emitEvent("loader", { save_loader: false, action: btnName })
              : btnName == "save"
              ? (loading.value = false)
              : (loadingback.value = false);
            let message = "";
            if (typeof error.response !== "undefined") {
              message = error.response.data.message;
            } else if (typeof error.message !== "undefined") {
              message = error.message;
            } else {
              message = "Something went wrong";
            }
            notificationFire(message, "error");
          });
      });
    };

    //Generate Current Page Toolbar Dynamic Buttons
    function generateHeaderToolbarButtons() {
      if (Object.keys(formData.value).length > 0) {
        return setCurrentPageActionButtons([
          {
            name: constants.general.SAVE,
            action: "save",
            class: "btn-primary me-3",
            isLoader: false,
          },
          {
            name: constants.general.SAVEANDBACK,
            action: "save_back",
            class: "btn-primary me-3",
            isLoader: false,
          },
          {
            name: constants.general.CANCEL,
            action: "cancel",
            class: "btn-secondary",
            isLoader: false,
          },
        ]);
      } else {
        return setCurrentPageActionButtons([
          {
            name: constants.general.BACK,
            action: "back",
            class: "btn-primary",
            isLoader: false,
            'iconClass':"bi bi-arrow-left fs-4"
          },
        ]);
      }
    }

    onMounted(async () => {
      setCurrentPageBreadcrumbs(formatText(route.name), [{"name": "Products", "path": "products"}, { name: formatText(route.name), active: true }]);
      generateHeaderToolbarButtons();
      productID.value = route.params.id;
      await getMasterOptionDetails();
    });

    onUpdated(async () => {
      generateHeaderToolbarButtons();
    });

    return {
      productID,
      tableData,
      formRef,
      formData,
      submitForm,
      disabled,
      loading,
      loadingback,
      pageLoading,
      changeStatus,
      showSkuCode,
      showSkuLabel,
      showAssignedSKu,
      constants,
      getKeyCountByValue,
      oldPoductData
    };
  },
};
</script>
